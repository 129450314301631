import { Column, Row } from '@/utils/request'
import exportFile from 'quasar/src/utils/export-file.js';import useQuasar from 'quasar/src/composables/use-quasar.js';

function wrapCsvValue(val: any, row?: Row, formatFn?: any) {
  let formatted = formatFn !== void 0 ? formatFn(val, row) : val
  formatted =
    formatted === void 0 || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export function exportToCsv(
  rows: Row[],
  columns: Column[],
  totalRow?: Row,
  getGroupedFieldFn?: any,
) {
  const $q = useQuasar()
  const rowsWithTotal = [...rows]
  if (rowsWithTotal.length && totalRow) {
    rowsWithTotal.push(totalRow)
  }

  const content = [
    columns
      .map(col => {
        if (col.field === 'groupByField' && getGroupedFieldFn) {
          return wrapCsvValue(getGroupedFieldFn('name'))
        }
        return wrapCsvValue(col.label)
      })
      .join(';'),
  ]
    .concat(
      rowsWithTotal.map(row =>
        columns
          .map(col => {
            const field = col.field === void 0 ? col.name : col.field
            let value =
              typeof col.field === 'function' ? col.field(row) : row[field]
            if (field === 'groupByField' && getGroupedFieldFn) {
              value = getGroupedFieldFn('field', row)
            }
            return wrapCsvValue(value, row, col.format)
          })
          .join(';'),
      ),
    )
    .join('\r\n')

  const status = exportFile(
    'stats.csv',
    '\ufeff' + content,
    'text/csv;charset=UTF-8;',
  )

  if (status !== true) {
    $q.notify({
      message: 'Browser denied file download...',
      color: 'negative',
      icon: 'warning',
    })
  }
}
